import React from 'react';

export function CartIcon({ onClick }) {
    return (
        <svg
            className="icon cart-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="9" cy="21" r="1"></circle>
            <circle cx="20" cy="21" r="1"></circle>
            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h12a2 2 0 0 0 2-1.61L23 6H6"></path>
        </svg>
    );
}

export function CloseIcon({ onClick }) {
    return (
        <svg
            className="icon icon-close"
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    );
}

export function SearchIcon({ onClick }) {
    return (
        <svg
            className="icon icon-search"
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
    );
}

export function PreviousIcon({ onClick }) {
    return (
        <svg
            className="icon icon-prev"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M15.41,7.41L14,6L8,12l6,6l1.41-1.41L10.83,12L15.41,7.41z" />
        </svg>
    );
}
export function NextIcon({ onClick }) {
    return (
        <svg
            className="icon icon-next"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M10,6L8.59,7.41l4.58,4.59l-4.58,4.59L10,18l6-6L10,6z" />
        </svg>
    );
}
export function ForwardIcon({ onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icop"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
        </svg>
    );
}
export function BackIcon({ onClick, width = 24 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            width={width}
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="19" y1="12" x2="5" y2="12" />
            <polyline points="12 19 5 12 12 5" />
        </svg>
    );
}
export function DownIcon({ onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" y1="5" x2="12" y2="19" />
            <polyline points="19 12 12 19 5 12" />
        </svg>
    );
}
export function UpIcon({ onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" y1="19" x2="12" y2="5" />
            <polyline points="5 12 12 5 19 12" />
        </svg>
    );
}
export function BurgerButton({ onClick }) {
    return (
        <div className="burger-button" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-burger"
            >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-close"
            >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>
    );
}

export function CheckmarkIcon({ onClick = () => {} }) {
    return (
        <svg
            className="icon icon-checkmark"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
        >
            <polyline
                points="10,25 20,35 40,15"
                stroke="green"
                strokeWidth="3"
                fill="none"
            />
        </svg>
    );
}

export function WarningIcon({ onClick = () => {} }) {
    return (
        <svg
            className="icon icon-warning"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            fill="none"
        >
            <polygon
                points="20,0 60,0 80,20 80,60 60,80 20,80 0,60 0,20"
                fill="red"
            />
            <line
                x1="40"
                y1="15"
                x2="40"
                y2="50"
                stroke="white"
                strokeWidth="4"
            />
            <circle cx="40" cy="60" r="3" fill="white" />
        </svg>
    );
}
export const RulerIcon = () => {
    return (
        <div
            className="icon icon-ruler"
            style={{ transform: 'rotate(-45deg)' }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 10">
                {/* Draw the ruler */}
                <rect
                    x="0"
                    y="0"
                    width="30"
                    height="10"
                    fill="none"
                    stroke="black"
                    strokeWidth="3"
                />
                <line x1="3" y1="10" x2="3" y2="7" stroke="black" />
                <line x1="6" y1="10" x2="6" y2="7" stroke="black" />
                <line x1="9" y1="10" x2="9" y2="6" stroke="black" />
                <line x1="12" y1="10" x2="12" y2="7" stroke="black" />
                <line x1="15" y1="10" x2="15" y2="7" stroke="black" />
                <line x1="18" y1="10" x2="18" y2="6" stroke="black" />
                <line x1="21" y1="10" x2="21" y2="7" stroke="black" />
                <line x1="24" y1="10" x2="24" y2="7" stroke="black" />
                <line x1="27" y1="10" x2="27" y2="6" stroke="black" />
            </svg>
        </div>
    );
};

export const TruckIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-truck"
    >
        <rect x="1" y="3" width="15" height="13"></rect>
        <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
        <circle cx="5.5" cy="18.5" r="2.5"></circle>
        <circle cx="18.5" cy="18.5" r="2.5"></circle>
    </svg>
);

export const ShareIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-share"
    >
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
    </svg>
);
export const CopyIcon = () => (
    <svg
        className="icon icon-copy"
        width="30"
        height="30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="5"
            y="1"
            width="12"
            height="17"
            stroke="black"
            fill="none"
            rx="1"
            strokeWidth="2"
            strokeDasharray="2,2"
        />
        <rect
            x="2"
            y="4"
            width="12"
            height="17"
            stroke="black"
            strokeWidth="2"
            rx="1"
            ry="1"
            fill="#fff"
        />
    </svg>
);

export const TrashIcon = ({
    fill = 'black',
    width = '50px',
    height = '50px',
}) => (
    <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        viewBox="0 0 24 28"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
    >
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="9" y1="10" x2="9" y2="21"></line>
        <line x1="12" y1="10" x2="12" y2="21"></line>
        <line x1="15" y1="10" x2="15" y2="21"></line>
    </svg>
);

export const TagIcon = () => {
    return (
        <svg
            width="11.2"
            height="16.8"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon tag-icon"
        >
            <path
                d="M1 8 L8 0.67 L15 8 V24 H1 V8"
                stroke="black"
                strokeWidth="3"
            />
            <circle cx="8" cy="10" r="1.67" fill="black" />
        </svg>
    );
};
export const QuestionIcon = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.877075 7.49972C0.877075 3.84204 3.84222 0.876892 7.49991 0.876892C11.1576 0.876892 14.1227 3.84204 14.1227 7.49972C14.1227 11.1574 11.1576 14.1226 7.49991 14.1226C3.84222 14.1226 0.877075 11.1574 0.877075 7.49972ZM7.49991 1.82689C4.36689 1.82689 1.82708 4.36671 1.82708 7.49972C1.82708 10.6327 4.36689 13.1726 7.49991 13.1726C10.6329 13.1726 13.1727 10.6327 13.1727 7.49972C13.1727 4.36671 10.6329 1.82689 7.49991 1.82689ZM8.24993 10.5C8.24993 10.9142 7.91414 11.25 7.49993 11.25C7.08571 11.25 6.74993 10.9142 6.74993 10.5C6.74993 10.0858 7.08571 9.75 7.49993 9.75C7.91414 9.75 8.24993 10.0858 8.24993 10.5ZM6.05003 6.25C6.05003 5.57211 6.63511 4.925 7.50003 4.925C8.36496 4.925 8.95003 5.57211 8.95003 6.25C8.95003 6.74118 8.68002 6.99212 8.21447 7.27494C8.16251 7.30651 8.10258 7.34131 8.03847 7.37854L8.03841 7.37858C7.85521 7.48497 7.63788 7.61119 7.47449 7.73849C7.23214 7.92732 6.95003 8.23198 6.95003 8.7C6.95004 9.00376 7.19628 9.25 7.50004 9.25C7.8024 9.25 8.04778 9.00601 8.05002 8.70417L8.05056 8.7033C8.05924 8.6896 8.08493 8.65735 8.15058 8.6062C8.25207 8.52712 8.36508 8.46163 8.51567 8.37436L8.51571 8.37433C8.59422 8.32883 8.68296 8.27741 8.78559 8.21506C9.32004 7.89038 10.05 7.35382 10.05 6.25C10.05 4.92789 8.93511 3.825 7.50003 3.825C6.06496 3.825 4.95003 4.92789 4.95003 6.25C4.95003 6.55376 5.19628 6.8 5.50003 6.8C5.80379 6.8 6.05003 6.55376 6.05003 6.25Z"
                fill="#000000"
            />
        </svg>
    );
};

export const EditIcon = () => {
    return (
        <svg
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="edit-icon icon"
        >
            <path
                d="M14 6L8 12V16H12L18 10M14 6L17 3L21 7L18 10M14 6L18 10M10 4L4 4L4 20L20 20V14"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ShoppingBagIcon = ({
    width = '24px',
    height = '24px',
    fill = '#000000',
}) => (
    <svg
        fill={fill}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 483.1 483.1"
        xmlSpace="preserve"
    >
        <g>
            <path
                d="M434.55,418.7l-27.8-313.3c-0.5-6.2-5.7-10.9-12-10.9h-58.6c-0.1-52.1-42.5-94.5-94.6-94.5s-94.5,42.4-94.6,94.5h-58.6
            c-6.2,0-11.4,4.7-12,10.9l-27.8,313.3c0,0.4,0,0.7,0,1.1c0,34.9,32.1,63.3,71.5,63.3h243c39.4,0,71.5-28.4,71.5-63.3
            C434.55,419.4,434.55,419.1,434.55,418.7z M241.55,24c38.9,0,70.5,31.6,70.6,70.5h-141.2C171.05,55.6,202.65,24,241.55,24z
             M363.05,459h-243c-26,0-47.2-17.3-47.5-38.8l26.8-301.7h47.6v42.1c0,6.6,5.4,12,12,12s12-5.4,12-12v-42.1h141.2v42.1
            c0,6.6,5.4,12,12,12s12-5.4,12-12v-42.1h47.6l26.8,301.8C410.25,441.7,389.05,459,363.05,459z"
            />
        </g>
    </svg>
);
export const DeadFace = () => {
    return (
        <svg
            className="icon icon-dead-face"
            xmlSpace="preserve"
            viewBox="10 20 80 70" // Adjusted viewBox to reduce top padding
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                strokeMiterlimit="10"
                strokeWidth="5"
                fill="none"
                strokeLinecap="round"
                d="M31.866 71.591c2.57-7.556 9.709-13 18.134-13s15.564 5.444 18.134 13"
            />
            <path
                d="M27.5 32.409l12 12"
                strokeWidth="5"
                fill="none"
                strokeMiterlimit="10"
            />
            <path
                d="M27.5 44.409l12-12"
                strokeWidth="5"
                fill="none"
                strokeMiterlimit="10"
            />
            <path
                d="M60.5 32.409l12 12"
                strokeWidth="5"
                fill="none"
                strokeMiterlimit="10"
            />
            <path
                d="M60.5 44.409l12-12"
                strokeWidth="5"
                fill="none"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
