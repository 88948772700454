import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
    ReactNode,
} from 'react';
import {
    collection,
    doc,
    setDoc,
    updateDoc,
    query,
    where,
    deleteDoc,
    onSnapshot,
} from 'firebase/firestore';
import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
import { formatCurrency } from '../utils';
import { useAuth, useFirestore } from '../firebase';
import { MessageContext } from './MessageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartItem, ShowSuccess, Adjustments } from '../types';

interface UseCartReturn {
    showSuccess: ShowSuccess | null;
    setShowSuccess: (success: ShowSuccess | null) => void;
    adjustments: Adjustments;
    addToCart: (item: CartItem) => Promise<void>;
    removeFromCart: (cartItemId: string) => Promise<void>;
    cart: CartItem[];
    cartSubTotal: number;
    cartDisplaySubTotal: string;
    clientSecret?: string;
    loadingCart: boolean;
    isItemInCart: (itemId: string) => boolean;
}

const CartContext = createContext<UseCartReturn | undefined>(undefined);

interface CartProviderProps {
    children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [cart, setCart] = useState<CartItem[]>([]);
    const [loadingCart, setLoadingCart] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>(null);
    const [showSuccess, setShowSuccess] = useState<ShowSuccess | null>(null);
    const [cartSubTotal, setCartSubTotal] = useState<number>(0);
    const [cartDisplaySubTotal, setCartDisplaySubTotal] = useState<string>('');
    const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);
    const [soldCartItems, setSoldCartItems] = useState<CartItem[]>([]);
    const [clientSecret, setClientSecret] = useState<string | undefined>('');
    const [adjustments, setAdjustments] = useState<Adjustments>({
        totalDiscount: 0,
        discount: [],
        shipping: [],
    });

    const { addMessage } = useContext(MessageContext);

    const auth = useAuth();
    const db = useFirestore();

    // Handles items that have been sold and need to be removed from cart
    useEffect(() => {
        if (soldCartItems.length > 0) {
            const updatePromises = soldCartItems.map(async ({ cartItemId }) => {
                const itemRef = doc(db!, 'cartItems', cartItemId);
                return updateDoc(itemRef, { status: 'removed' }).catch(
                    (error) => {
                        console.error(
                            `Error updating item ${cartItemId}:`,
                            error
                        );
                        return null;
                    }
                );
            });

            Promise.all(updatePromises)
                .then(() => console.log('All items updated successfully'))
                .catch((error) =>
                    console.error(
                        'An error occurred during bulk updates:',
                        error
                    )
                );

            soldCartItems.forEach(({ name }) => {
                addMessage('cart-update', [
                    `🙄 Doh! ${name} has just been sold and removed from your cart.`,
                    'cart-update',
                    { mutable: true, global: true },
                ]);
            });
        }
    }, [soldCartItems, db, addMessage]);

    useEffect(() => {
        setIsCheckingOut(
            location.pathname.includes('/checkout') &&
                !location.pathname.includes('/complete')
        );
    }, [location.pathname]);

    useEffect(() => {
        if (!auth || !db) return;

        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const itemsRef = collection(db, 'cartItems');
                const q = query(
                    itemsRef,
                    where('userId', '==', currentUser.uid),
                    where('status', 'in', ['in-cart', 'sold-out'])
                );
                const unsubscribe = onSnapshot(q, (snapshot) => {
                    const inCartItems = snapshot.docs
                        .filter((doc) => doc.data().status === 'in-cart')
                        .map((doc) =>
                            Object.assign(
                                {
                                    cartItemId: doc.id,
                                },
                                { ...(doc.data() as CartItem) }
                            )
                        );
                    setCart(inCartItems);

                    if (!inCartItems.length && isCheckingOut) {
                        navigate('/cart');
                    }

                    const soldOutItems = snapshot.docs
                        .filter((doc) => doc.data().status === 'sold-out')
                        .map((doc) => ({
                            ...(doc.data() as CartItem),
                            cartItemId: doc.id,
                        }));
                    setSoldCartItems(soldOutItems);

                    setLoadingCart(false);
                });

                return unsubscribe;
            } else {
                signInAnonymously(auth)
                    .then(({ user }) => setUser(user))
                    .catch((error) =>
                        console.error('Anonymous sign-in error:', error)
                    );
            }
        });

        return unsubscribeAuth;
    }, [auth, db, navigate, isCheckingOut]);

    useEffect(() => {
        if (!user || !db) return;

        const docRef = doc(db, 'carts', user.uid);
        const adjustmentsRef = collection(docRef, 'adjustments');
        const unsubscribe = onSnapshot(adjustmentsRef, (snapshot) => {
            const adjustmentsData = snapshot.docs.map((doc) => ({
                ...doc.data().promos,
            }));
            if (adjustmentsData[0]) setAdjustments(adjustmentsData[0]);
        });
        return unsubscribe;
    }, [user, db]);

    const addToCart = useCallback(
        async (item: CartItem): Promise<void> => {
            if (!user || !db) return;

            const itemExists = cart.some((cartItem) => cartItem.id === item.id);
            if (itemExists) {
                setShowSuccess({
                    status: 'error',
                    message: 'Item already in cart',
                });
                return;
            }

            try {
                const cartItemRef = doc(collection(db, 'cartItems'));
                await setDoc(cartItemRef, {
                    ...item,
                    userId: user.uid,
                    status: 'in-cart',
                });
                const cartItem: CartItem = Object.assign(
                    { cartItemId: `${item.id}-${Date.now()}` }, // Generate a unique ID
                    { ...item }
                );
                setShowSuccess({
                    status: 'success',
                    message: 'Item added to cart',
                    item: cartItem,
                });
            } catch (error) {
                console.error('Error adding item to cart:', error);
            }
        },
        [user, cart, db]
    );

    const removeFromCart = useCallback(
        async (cartItemId: string): Promise<void> => {
            if (!user || !db) return;

            try {
                const cartItemRef = doc(db, 'cartItems', cartItemId);
                await deleteDoc(cartItemRef);
                setCart(cart.filter((item) => item.cartItemId !== cartItemId));
            } catch (error) {
                console.error('Error removing item from cart:', error);
            }
        },
        [user, cart, db]
    );

    useEffect(() => {
        const subTotal = cart.reduce(
            (total, item) => total + item.salePrice,
            0
        );
        setCartSubTotal(subTotal);
        setCartDisplaySubTotal(formatCurrency(subTotal / 100));
    }, [cart]);

    const contextValue: UseCartReturn = useMemo(
        () => ({
            cart,
            user,
            showSuccess,
            cartSubTotal,
            cartDisplaySubTotal,
            clientSecret,
            loadingCart,
            adjustments,
            setClientSecret,
            addToCart,
            removeFromCart,
            setShowSuccess,
            isItemInCart: (itemId: string) =>
                cart.some((item) => item.id === itemId),
        }),
        [
            cart,
            user,
            showSuccess,
            cartSubTotal,
            cartDisplaySubTotal,
            clientSecret,
            loadingCart,
            adjustments,
            addToCart,
            removeFromCart,
        ]
    );

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = (): UseCartReturn => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
