import React, { useState } from 'react';
import './HamburgerMenu.scss';

const HamburgerMenu = ({ onClick }) => {
    const [open, setOpen] = useState(false);

    return (
        <button
            className={`hamburger ${open ? 'open' : ''}`}
            onClick={onClick}
            aria-label="Menu"
        >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
        </button>
    );
};

export default HamburgerMenu;
